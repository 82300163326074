import routerOptions0 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.1.0_@parcel+watcher@2.4.1_@types+node@18.19.60_eslint@8.57.1_ior_xjyukondtfbfphoek4zgpfp6ku/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/library-website-nuxt/library-website-nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}